<template>
  <div class="usercont">
    <div class="user-title">修改密码</div>
    <div class="pwd-cell">
      <span class="pwd-label">输入原密码</span>
      <input class="user-input" type="password" placeholder="请输入原密码" v-model="oldPassword" />
    </div>
    <div class="pwd-cell">
      <span class="pwd-label">修改密码</span>
      <input
        class="user-input"
        type="password"
        placeholder="请输入新密码"
        v-model="password"
        @blur="isPwdHint = false"
        @focus="isPwdHint = true"
      />
      <div class="errtip" v-show="errorPasswordMsg">{{ errorPasswordMsg }}</div>

      <popup-hint :top="0" :left="360" v-show="isPwdHint">
        <template #hint>
          <div class="hint-block">
            <span class="hint-text"><svg-icon icon-class="check"></svg-icon>长度8-16位字符</span>
            <span class="hint-text"
              ><svg-icon icon-class="check"></svg-icon
              >{{ "由字母、数字或字符_!@#$%^&*中至少两种组成（区分大小写）" }}</span
            >
          </div>
        </template>
      </popup-hint>
    </div>
    <div class="pwd-cell">
      <span class="pwd-label">确认密码</span>
      <input
        class="user-input"
        type="password"
        placeholder="请确认新密码"
        v-model="passwordConfirm"
      />
      <div class="errtip" v-show="errorRepeatPasswordMsg">{{ errorRepeatPasswordMsg }}</div>
    </div>
    <div class="confirm" :class="[isValid ? null : 'confirm-disabled']" @click="confirmPassword">
      确定
    </div>
  </div>
</template>

<script>
import { changePwd } from "@/api/portal.js";
import userMixin from "@/mixins/user-mixin.js";
import { delCookie } from "@/tool";
import { pattern_psd } from "@/tool/pattern.js";

export default {
  data() {
    return {
      isPwdHint: false,
      oldPassword: "",
      password: "",
      passwordConfirm: "",
      errorPasswordMsg: "",
      errorRepeatPasswordMsg: "",
      isValid: false
    };
  },
  watch: {
    password: function (val) {
      this.errorPasswordMsg = pattern_psd.test(val) ? "" : "密码格式有误";
      if (!val) {
        this.errorPasswordMsg = "";
      }
      this.isValid =
        val &&
        !this.errorPasswordMsg &&
        this.passwordConfirm &&
        !this.errorRepeatPasswordMsg &&
        this.oldPassword;
    },
    passwordConfirm: function (val) {
      this.errorRepeatPasswordMsg = val === this.password ? "" : "两次密码不一致";
      if (!val) {
        this.errorRepeatPasswordMsg = "";
      }
      this.isValid =
        val &&
        !this.errorRepeatPasswordMsg &&
        this.password &&
        !this.errorPasswordMsg &&
        this.oldPassword;
    }
  },
  mixins: [userMixin],
  methods: {
    async confirmPassword() {
      if (!this.isValid) return;
      const res = await changePwd({
        id: this.user.id,
        oldPassword: this.oldPassword,
        password: this.password,
        passwordConfirm: this.passwordConfirm
      });
      if (!res.success) {
        this.$message.error(res.message);
        return;
      }
      window.location.href = "/logout";
      delCookie("user");
      localStorage.clear();
      this.$router.replace("/login");
    }
  }
};
</script>

<style lang="less" scoped>
.pwd-cell {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .pwd-label {
    display: block;
    width: 70px;
    margin-right: 24px;
    color: #4e5969;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
  }
  .user-input {
    width: 248px;
  }
}

.confirm {
  width: 248px;
  height: 32px;
  margin-left: 94px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  background-color: #747aef;
  cursor: pointer;
}
.confirm-disabled:extend(.confirm) {
  background-color: #c6c4f8;
  cursor: not-allowed;
}
.errtip {
  left: 94px;
}
</style>
