import request from "tsl-axios";

/**
 * 用户中心 修改密码
 */
export function changePwd(params) {
  return request.post("/userInfo/updatePwd", params).then((response) => {
    return response;
  });
}
/**
 * 用户中心 修改头像/昵称
 */
export function changeUserinfo(params) {
  return request.post("/userInfo/updateBaseInfo", params).then((response) => {
    return response;
  });
}
/**
 * 用户中心 重新绑定手机-邮箱验证
 */
export function emailVerification(params) {
  return request.post("/userInfo/rebindByEmail", params).then((response) => {
    return response;
  });
}
/**
 * 用户中心 重新绑定手机-手机验证
 */
export function phoneVerification(params) {
  return request.post("/userInfo/rebindByPhone", params).then((response) => {
    return response;
  });
}
/**
 * 用户中心 重新绑定手机-校验新手机
 */
export function newPhoneVerification(params) {
  return request.post("/userInfo/rebindPhone/submit", params).then((response) => {
    return response;
  });
}
/**
 * 用户中心 重新绑定邮箱-校验新邮箱
 */
export function newEmailVerification(params) {
  return request.post("/userInfo/rebindEmail/submit", params).then((response) => {
    return response;
  });
}
